import React from 'react'


import {createBrowserRouter} from "react-router-dom";
import {MainScreen} from "./components/MainScreen";
import NavBar from "./widgets/NavBar";
import PatientList from "./components/PatientList";
import {Grid424} from "./widgets/Grids";
import {Questionnaire} from "./components/Questionnaire";


const logedInScreen = () => <>
    <NavBar/>
    <div style={{marginTop: 100}}></div>
    <Grid424>
        <PatientList/>
    </Grid424>
</>


export default createBrowserRouter([
    {
        path: "/",
            element: logedInScreen()
        ,
        //loader: async () => getLsPipelines("all"),
    },
    {
        path: "/dossiers",
        element: logedInScreen()
        ,
        //loader: async ({params}) => getPipelineInstance(`/${params["*"]}`)
    },
    {
        path: "/dossiers/*",
        element: <Questionnaire/>
        ,
        //loader: async ({params}) => getPipelineInstance(`/${params["*"]}`)
    }
])
