import React from "react";
import Link from "../widgets/Link";
import {Grid424} from "../widgets/Grids";



export const MainScreen = ({}) => {


    return <Grid424>

        <Link href={"/dossiers"} caption={"Dossiers"}/>
    </Grid424>
}