import React, {useState} from "react";
import {format, subMonths} from "date-fns";
import { fr } from 'date-fns/locale'
import {DefaultButton, AlternativeButton, SmallButton} from "../widgets/Buttons";
import ModalPopup from "../widgets/ModalPopup";
import {useNavigate} from "react-router-dom";
import {Datepicker} from "flowbite-react";
import {Step} from "../widgets/Stepper";
import {Grid131313, Grid424, Grid636} from "../widgets/Grids";
import Link from "../widgets/Link";
import {last} from "radash";

let idGEN = 1111

const poursuivreSaisieTxt = n =>
    `Poursuivre la ${n}e saisie du questionnaire`

export default ({healthOverviewDispatch, onCancel, dossierGuid}) => {

    const navigate  = useNavigate()

    const [newDataEntry, setNewDataEntry] = useState(null)

    const [newDataEntryWasCreated, setNewDataEntryWasCreated] = useState(false)

    // format(currentVisit.date, "d MMM yyyy")

    const [visits, setVisits] = useState([
        {id: 1, date: subMonths(new Date(), 46), infos: 19},
        {id: 11, date: subMonths(new Date(), 31), infos: 21},
        {id: 111, date: subMonths(new Date(), 1), infos: 23}
    ])


    if(newDataEntryWasCreated) {
        return <PopNewRoundCreated startQuestionnaire={() => {
            navigate(`/dossiers/${dossierGuid}`)
        }}/>
    }

    if(newDataEntry) {
        return <PopNewRound
            newDataEntry={newDataEntry}
            onCancel={() => setNewDataEntry(null)}
            onCreateNew={() => {
                idGEN++

                setVisits([
                   ...visits,
                   {id: idGEN, date: newDataEntry['visitDate'] || new Date(), infos: 0}
                ])

                setNewDataEntry(null)
                setNewDataEntryWasCreated(true)
            }}
        />
    }


    return <ModalPopup
        title={"Vue d'ensemble des saisies du questionnaires"}
        onClose={onCancel}
        footer={
            <div className={"inline-flex items-center justify-center"}>

                <DefaultButton
                    caption={poursuivreSaisieTxt(visits.length)}
                    onClick={
                        () => {
                            navigate(`/dossiers/${dossierGuid}`)
                        }
                    }
                />
                <span style={{marginLeft: 10}}/>
                <DefaultButton
                    caption={"Créer une nouvelle saisie du questionnaire"}
                    onClick={
                        () => {
                            setNewDataEntry({})
                        }
                    }
                />
                <AlternativeButton
                    caption={"Fermer"}
                    onClick={onCancel}
                />
            </div>
        }
    >
        <Grid424>

            <DataEntryTimeline
                visits={visits}
                dossierGuid={dossierGuid}
                onCreateNew={() => setNewDataEntry({})}
            />
        </Grid424>

    </ModalPopup>
}


const DataEntryTimeline = ({visits, dossierGuid, onCreateNew}) => {


    const currentVisit = last(visits)

    const previousVisits = [...visits].slice(0, -1)


    return <ol className="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">

        <Step
            isCompleted={true}
            title={"Antécédents et données démographiques"}
            details={"Saisie complétée"}
        />
        {
            previousVisits.map((v, idx) =>
                <Step
                    title={`${idx+1}e saisie du questionnaire`}
                    isCompleted={true}
                    details={
                        <span>Visite du {format(v.date, 'd MMMM yyyy', {locale: fr})}</span>
                    }
                />
            )
        }
        <Step
            isCompleted={false}
            title={`${visits.length}e saisie du questionnaire`}
            details={
                <span>
                    <b>Visite du {format(currentVisit.date, 'd MMMM yyyy', {locale: fr})}</b><br/>
                    Saisie en cours <Link caption={"poursuivre la saisie"} href={`/dossiers/${dossierGuid}`}/>.
                    <br/>
                    Si la saisie est complétée, vous pouvez <Link onClick={onCreateNew} caption={"créer une nouvelle saisie"}/>.
                </span>
            }
        />
    </ol>
}


const PopNewRound = ({onCancel, onCreateNew, newDataEntry}) => {


   return <ModalPopup
        title={"Créer une nouvelle Saisie du questionnaire"}
        onClose={onCancel}
        footer={
            <div className={"inline-flex items-center justify-center"}>
               <DefaultButton
                   caption={"Créer la nouvelle saisie"}
                   onClick={onCreateNew}
               />
                <AlternativeButton
                    caption={"Annuler"}
                    onClick={onCancel}
                />
            </div>
        }
    >

       <Grid424>
           <p>
               Une saisie du questionnaire se fait généralement suite à une visite du patient, veuillez saisir la date de visite.
           </p>
           <Datepicker
               value={newDataEntry['visitDate']}
               autoHide={true}
               onChange={value => {
                   newDataEntry['visitDate'] = value
               }}
           />
       </Grid424>
   </ModalPopup>
}


const PopNewRoundCreated = ({startQuestionnaire}) => {

   return <ModalPopup
        title={"Nouvelle saisie du questionnaire créée"}
        footer={
            <div className={"inline-flex items-center justify-center"}>
               <DefaultButton
                   caption={"Débuter la nouvelle saisie"}
                   onClick={startQuestionnaire}
               />
            </div>
        }
    >

       <Grid424>
           <p>
               Vous pouvez maintenant débuter la saisie du questionnaire.
           </p>
       </Grid424>
   </ModalPopup>
}