import React, {useEffect, useState} from "react"
import {format, getUnixTime} from "date-fns"
import {obervationTypeById} from "../metaDataProcessed"
import {sort} from "radash"
import {PencilIcon} from "../widgets/CustomIcons";
import {EditObservationPopup} from "./EditObservationPopup";
import Note from "../widgets/Note";
import {AlternativeButton, ExtraSmallButton} from "../widgets/Buttons";


function excludeA(adata) {

    if (
        adata.label === "Date de visite" ||
        adata.label === "Notes"
    ) {
        return true
    }
    return false
}


export const ObservationView = ({currentVisit, healthOverviewState, healthOverviewDispatch, row}) => {

    const observationTypeId = row.o.o.id

    const observationType = obervationTypeById[observationTypeId]

    return <td
        colSpan={healthOverviewState.visits.length + 1}
        className={"px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"}
    >
        <div style={{marginLeft: `${row.depth * 2}rem`}}>
            <div style={{cursor: 'pointer'}}>

                    <h3>{row.o.label}</h3>
                    {
                        <ObservationInstancesTable
                            visitObservations={row.o.visitObservations}
                            observationTypeId={observationTypeId}
                            observationType={observationType}
                            healthOverviewDispatch={healthOverviewDispatch}
                            currentVisit={currentVisit}
                            row={row}
                        />
                    }

            </div>
        </div>
    </td>
}

const ObservationInstancesTable = ({
    observationType, observationTypeId, healthOverviewDispatch, visitObservations, currentVisit, row
}) => {


    const adatas = observationType.additionalData.filter(a => !excludeA(a))

    const visitObservationsDesc = sort(visitObservations, vo => getUnixTime(vo.visit.date), true)

    const [editedVisitObservation, setEditedVisitObservation] = useState(null)

    const [editedNewVisitObservation, setEditedNewVisitObservation] = useState(null)

    const saveEdits = fieldValues => {

        healthOverviewDispatch({
            name: "updateObservationFields",
            visitId: editedVisitObservation.visit.id,
            observationTypeId,
            fieldValues
        })

        setEditedVisitObservation(null)
    }


    const renderTableColumnHeaders = () => adatas.map(adata =>
        <th className="px-6 py-4" key={`header-${adata.fieldName}`}>
            {adata.label}
        </th>
    )

    const renderField = (adata, value) => {
        switch (adata.type) {
            case "date":
            case "dateD":
            case "dateF":
            case "dateFF":
                return value ? format(value, "d MMM yy") : "---"
            case "nombre":
                return value
            case "text":
                return value
            case "choix_multiple_non_ex":
            case "choix_multiples":
                return value && JSON.stringify(value)
            case "choice":
                return value
        }
    }

    function * genObs() {
        for (const vo of visitObservationsDesc) {
            if (!vo.observations) {
                break
            }
            for (const observation of vo.observations) {
                yield {
                    ...vo,
                    observation
                }
            }
        }
    }

    const renderTableRows = () => [...genObs()].map(vo => {
        return <tr
            key={`row-${vo.observation.id}`}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            onClick={() => setEditedVisitObservation(vo)}
        >

            {
                adatas.map(adata =>
                    <td
                        className="px-6 py-4"
                        key={`adata-${adata.fieldName}-${vo.observationTypeId}-${vo.visit.id}`}
                    >
                        {
                            vo.observation ?
                                renderField(adata, vo.observation.fieldValues[adata.fieldName]) :
                                "---"
                        }
                    </td>
                )
            }

            <td className="px-6 py-4">
                <Note text={"voici une note"} date={new Date()}/>
                <Note text={"et une note, avec pas mal plus de texte"} date={new Date()}/>
            </td>

        </tr>

    })

    return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {
            editedVisitObservation &&
            <EditObservationPopup
                title={observationType.name}
                observation={editedVisitObservation.observation}
                adatas={adatas}
                onSaveClick={fieldValues => saveEdits(fieldValues)}
                onCancelClick={() => setEditedVisitObservation(null)}
            />
        }
        {
            editedNewVisitObservation &&
            <EditObservationPopup
                title={observationType.name}
                observation={null}
                adatas={adatas}
                onSaveClick={fieldValues => {

                    healthOverviewDispatch({
                        name: "newObservation",
                        visit: currentVisit,
                        observationTypeId,
                        fieldValues
                    })
                    setEditedNewVisitObservation(null)
                }}
                onCancelClick={() => setEditedNewVisitObservation(null)}
            />
        }
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                {renderTableColumnHeaders()}
                <th>Notes</th>
            </tr>
            </thead>
            <tbody>
            {renderTableRows()}
            </tbody>
        </table>

            {
                observationType.isMultiple &&  <div style={{margin: 10}}>
                    <AlternativeButton
                        caption={`ajouter ${observationType.prefixForMultiple}`}
                        onClick={() => {

                            const newO = {
                                visitId: currentVisit.id,
                                observationTypeId: observationTypeId,
                                fieldValues: {}
                            }

                            setEditedNewVisitObservation(newO)
                        }}
                    />
                </div>
            }
    </div>
}


