import React from "react";

export const Grid424 = ({children}) =>
    <div className="flex flex-row">
        <div className="basis-1/4"></div>
        <div className="basis-1/2">{children}</div>
        <div className="basis-1/4"></div>
    </div>


export const Grid636 = ({children}) =>
    <div className="flex flex-row">
        <div className="basis-1/6"></div>
        <div className="basis-2/3">{children}</div>
        <div className="basis-1/6"></div>
    </div>


export const Grid131313 = ({children}) =>
    <div className="flex flex-row">
        <div className="basis-1/3"></div>
        <div className="basis-1/3">{children}</div>
        <div className="basis-1/3"></div>
    </div>