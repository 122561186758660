import React from "react"



export default ({children, onClick}) =>
    <div
        className={"block max-w-smz p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"}
        onClick={onClick}
    >
        {children}
    </div>