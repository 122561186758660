import React from 'react'
import ReactDOM from 'react-dom/client'
import "./style.css"

import {RouterProvider} from "react-router-dom";
import Router from "./Router";


const rootElement = document.getElementById('root')

if (!rootElement) throw new Error('Failed to find the root element')



ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
        <RouterProvider router={Router}/>
    </React.StrictMode>
)

