import React, {useEffect, useState} from "react"
import Field from "./Field"
import Label from "../widgets/Label";
import Select from "../widgets/Select";
import {DefaultButton, AlternativeButton, ExtraSmallButton, SmallButton} from "../widgets/Buttons";
import Note from "../widgets/Note";
import ModalPopup from "../widgets/ModalPopup";
import {PencilIcon} from "../widgets/CustomIcons";



const typesVasculites = [
  {category: "Gros vaisseaux",
   types: [
     {
       name: "Takayasu"
     },
     {
       name: "Artérite à cellules géantes (artérite temporale)"
     },
   ]},
  {category: "Moyens vaisseaux",
   types: [
     {
       name: "Polyartérite noueuse (PAN)"
     },
     {
       name: "Kawasaki"
     }
   ]},
  {category: "Petits vaisseaux / ANCA",
   types: [
     {
       name: "Granulomatose avec polyangiite (GPA / Wegener)"
     },
     {
       name: "Polyartérite microscopique (PAM)"
     },
     {
       name: "Granulomatose éosinophilique avec polyangiite (GEPA)"
     }
   ]},
  {category: "Petits vaisseaux / complexes immuns",
   types: [
     {
       name: "Anti GBM (Goodpasture)"
     },
     {
       name: "Vasculite cryoglobulinémique"
     },
     {
       name: "Vasculite à IgA (purpura de Henoch Schonlein)"
     },
     {
       name: "Vasculite urticarienne hypocomplémentémique (vasculite anti C1q)"
     },
     {
       name: "Autre (Petits vaisseaux / complexes immuns)", needsAdditionalSpecs: true
     }
   ]},
  {category: "Taille variable",
   types: [
     {
       name: "Behcet"
     },
     {
       name: "Cogan"
     }
   ]},
  {category: "Vasculite isolée à un organe",
   types: [
     {
       name: "Vasculite cutanée"
     },
     {
       name: "Vasculite isolée du système nerveux central"
     },
     {
       name: "Aortite isolée"
     },
     {
       name: "Autre (Vasculite isolée à un organe)", needsAdditionalSpecs: true
     }
   ]},
  {category: "vasculite associée à une maladie systémique",
   types: [
     {
       name: "lupus érythémateux disséminé"
     },
     {
       name: "arthrite rhumatoïde"
     },
     {
       name: "sarcoïdose"
     },
     {
       name: "Autre (vasculite associée à une maladie systémique)", needsAdditionalSpecs: false
     }
   ]},
  {category: "Vasculite associée à une étiologie probable",
   types: [
     {
       name: "hépatite C"
     },
     {
       name: "hépatite B"
     },
     {
       name: "syphilis"
     },
     {
       name: "autre infection"
     },
     {
       name: "médicaments / drogues"
     },
     {
       name: "vasculite à ANCA associée à médicaments ou drogues"
     },
     {
       name: "néoplasie"
     },
     {
       name: "Autre (Vasculite associée à une étiologie probable)", needsAdditionalSpecs: true
     }
   ]}
]


export const VasculiteForm = ({
    gender,
    categoryVasculite,
    anneeNaissance,
    vasculiteDetail,
    dateDebutSymptomes,
    notes,
    isEditMode,
    onSave // fields {key: value}
}) => {


    const [lGender, setLGender] = useState(gender)

    const [lDateDebutSymptomes, setLDateDebutSymptomes] = useState(dateDebutSymptomes)

    const [lCategoryVasculite, setLCategoryVasculite] = useState(categoryVasculite)

    const [vasculiteDetailOptions, setVasculiteDetailOptions] = useState([])

    const [lVasculiteDetail, setLVasculiteDetail] = useState(vasculiteDetail)

    const [lNotes, setLNotes] = useState(notes)

    const [isEditing, setEditing] = useState(isEditMode)


    useEffect(() => {

        const details = typesVasculites.find(o => o.category === lCategoryVasculite)

        setVasculiteDetailOptions(details && details.types)
        setLVasculiteDetail('')

    }, [lCategoryVasculite])

    const edit = () => setEditing(true)

    const renderViewPanel = () => <div className="grid grid-cols-8 gap-6">
        <div>Sexe: Homme</div>
        <div onClick={edit}>naissance: 1954</div>
        <div onClick={edit}>Début des symptômes Aout 2014</div>
        <div onClick={edit}>Date du diagnostic Janvier 2015</div>
        <div onClick={edit}>Type de Vasculite: Taille Variable, Behcet</div>
        <div onClick={edit} className={"col-span-2"}>Notes: ...ceci est une note</div>
        <div><PencilIcon onClick={edit}/></div>
    </div>

    const popEditPanel = () => <div className="grid grid-cols-4 gap-6">
        <div>
            <Label text={"Sexe"}/>
            <
                Select
                value={lGender}
                onChange={e => setLGender(e.target.value)}
            >
                <option value={null}>choisir</option>
                <option key={"H"}>Homme</option>
                <option key={"F"}>Femme</option>
            </Select>
        </div>
        <Field
            fieldValues={{naissance: anneeNaissance}}
            isEditMode={true}
            onFieldEdit={(name, value) => {
            }}
            adata={{
                fieldName: 'naissance',
                label: 'année de naissance',
                type: "nombre"
            }}
        />
        <Field
            fieldValues={{date_symptomes: lDateDebutSymptomes}}
            isEditMode={true}
            onFieldEdit={(name, value) => {
                setLDateDebutSymptomes(value)
            }}
            adata={{
                fieldName: 'date_symptomes',
                label: 'début des symptomes',
                type: "date"
            }}
        />
        <Field
            fieldValues={{}}
            isEditMode={true}
            onFieldEdit={(name, value) => {
            }}
            adata={{
                fieldName: 'date_diagnostique',
                label: 'date du diagnostique',
                type: "date"
            }}
        />
        <div>
            <Label text={"Type de Vasculite"}/>
            <
                Select
                value={lCategoryVasculite}
                onChange={e => setLCategoryVasculite(e.target.value)}
            >
                <option value={null}>choisir</option>
                {
                    typesVasculites.map(
                        t =>
                            <option key={t.category}>{t.category}</option>
                    )
                }
            </Select>
        </div>
        <div>
            <Label text={"Détails"}/>
            <
                Select
                value={lVasculiteDetail}
                onChange={e => setLVasculiteDetail(e.target.value)}
            >
                {
                    vasculiteDetailOptions &&
                    vasculiteDetailOptions.map(
                        t =>
                            <option key={t.name}>{t.name}</option>
                    )
                }
            </Select>
        </div>
        <Field
            fieldValues={{notes: lNotes}}
            onFieldEdit={(name, value) => {
                setLNotes(value)
            }}
            isEditMode={true}
            adata={{
                fieldName: 'notes',
                label: 'Notes',
                type: "text"
            }}
        />
    </div>


    return isEditMode ? popEditPanel() : renderViewPanel()
}