import React from "react";


export const Step = ({title, isCompleted, details}) => {

    return <li className="mb-10 ms-6">

        <span
            className={
                "absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-4 ring-white " +
                `${isCompleted ? 'bg-green-200 dark:ring-gray-900 dark:bg-green-900' : 'bg-gray-100 dark:ring-gray-900 dark:bg-gray-700'}`
            }
        >

            <svg
                className={
                    `w-3.5 h-3.5 ${isCompleted ? "text-green-500 dark:text-green-400" : "bg-gray-100 text-gray-500 dark:text-gray-400"}`
                }
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox={"0 0 16 12"}
            >
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={ isCompleted ? "M1 5.917 5.724 10.5 15 1.5" : ""}
                />
            </svg>

        </span>
        <h3 className="font-medium leading-tight">{title}</h3>
        <p className="text-sm">{details}</p>
    </li>

}