import React, {useEffect, useState} from 'react'
import ModalPopup from "../widgets/ModalPopup";
import {VasculiteForm} from "./CustomForms";
import {DefaultButton, AlternativeButton} from "../widgets/Buttons";
import DataEntryRoundsOverview from "./DataEntryRoundsOverview";


export default ({}) => {

    const [patientList, setPatientList] = useState([
        {guid: "abc1234", gender: "H"},
        {guid: "def456", gender: "F"}
    ])

    let genID = 123345;

    const [newDossier, setNewDossier] = useState(null)

    const [selectedDossier, setSelectedDossier] = useState(null)


    const popNewDossier = () => {
        return <ModalPopup
            title={"Créer Nouveau Dossier"}
            onClose={() => setNewDossier(null)}
        >
            <VasculiteForm isEditMode={true}/>
            <DefaultButton
                caption={"Sauvegarder"}
                onClick={() => {

                    setPatientList([...patientList, newDossier])
                    setSelectedDossier(newDossier)
                    setNewDossier(null)
                }}
            />
            <AlternativeButton
                caption={"Annuler"}
                onClick={() => setNewDossier(null)}
            />
        </ModalPopup>
    }

    const popDataEntryRoundsOverview = () => {
        return <DataEntryRoundsOverview
                dossierGuid={selectedDossier.guid}
                onCancel={() => setSelectedDossier(null)}
        />
    }

    return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {newDossier && popNewDossier()}
        {selectedDossier && popDataEntryRoundsOverview()}
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                </th>
                <th scope="col" className="px-6 py-3">
                    Sexe
                </th>
                <th scope="col" className="px-6 py-3">
                    Type de Vasculite
                </th>
                <th scope="col" className="px-6 py-3">
                    Date du Diagnostic
                </th>
                <th scope="col" className="px-6 py-3">
                </th>
            </tr>
            </thead>
            <tbody>
            {
                patientList.map(p => <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    key={p.guid}
                >
                    <th scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                        <a onClick={() => setSelectedDossier(p)}>{p.guid}</a>
                    </th>
                    <td className="px-6 py-4">
                        {p.gender}
                    </td>
                    <td className="px-6 py-4">
                    </td>
                    <td className="px-6 py-4">
                    </td>
                    <td className="px-6 py-4">
                    </td>
                </tr>)
            }
            </tbody>
        </table>
        <DefaultButton
            caption={"Nouveau Dossier"}
            onClick={() => {
                genID++
                const d = {
                    guid: `348tuj39${genID}`
                }
                setNewDossier(d)
            }}
        />
    </div>
}



