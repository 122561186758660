
import {group, mapValues} from "radash";
import questionnaire from "../metaData";
import {addDays, differenceInDays, max} from "date-fns";
import {obervationTypeById} from "../metaDataProcessed";


const buildView = (observations, visits, metaData, visibleSectionLabel, selectedObservationTypeId) => {

    visits = visits.sort(v => v.id)

    const observationsByTypeId =
        group(observations, o => o.observationTypeId)


    function* trav(children, depth, visibleSectionLabel) {

        for (const row of children) {
            switch (depth) {
                case 0:
                    /*yield {
                        depth, type: 'Section', o: row, label: row.label,
                        visitObservations: visits.map(v => ({visit: v}))
                    }*/
                    if (visibleSectionLabel === row.label) {
                        yield* trav(row.children, depth + 1)
                    }
                    break
                case 1:
                    /*yield {
                        depth, type: 'SubSection', o: row, label: row.label,
                        visitObservations: visits.map(v => ({visit: v}))
                    }*/
                    yield* trav(row.children, depth + 1)
                    break
                case 2:
                    yield {
                        depth, type: 'SubSubSection', o: row, label: row.label,
                        visitObservations: visits.map(v => ({visit: v}))
                    }
                    yield* trav(row.children, depth + 1)
                    break
                case 3:

                    const observationsOfType = observationsByTypeId[row.id]

                    const observationsForVisit = visitId => {
                        if (!observationsOfType) {
                            return null
                        }

                        return observationsOfType.filter(o => visitId === o.visitId)
                    }

                    const obsRow = {
                        depth,
                        type: 'Observations',
                        o: row,
                        label: row.name,
                        visitObservations: visits.map(v => {
                            return {
                                visit: v,
                                observationTypeId: row.id,
                                observations: observationsForVisit(v.id),
                            }
                        })
                    }

                    yield obsRow

                    if (obsRow.o.id === selectedObservationTypeId) {
                        yield {
                            depth: 0,
                            type: 'SelectedObservationTypePanel',
                            o: obsRow
                        }
                    }
                    break
                default:
                    throw Error(`should not have gotten here ${depth} ${row}`)
            }
        }
    }

    return [...trav(metaData.children, 0, visibleSectionLabel)]
}

export const healthOverviewReducer = (state, action) => {


    const countObservationsPerTab = observations =>
        mapValues(
            group(
                observations.map(o => obervationTypeById[o.observationTypeId].tab),
                    i => i
            ),
            v => v.length || null
        )


    switch (action.name) {
        case "init": {
            const obs = [
                {id: 1, visitId: 1, observationTypeId: "6500",
                    fieldValues:
                        {
                            "Date debut des symptomes": new Date(),
                            "Date remission": new Date()
                        }
                }
            ]
            const visits = [{id: 1, date: new Date(2021, 6, 2)}]
            return {
                idGen: 100,
                visibleSectionLabel: null,
                observations: obs,
                visits,
                rows: buildView(obs, visits, questionnaire, null, null),
                observationCountPerTab: countObservationsPerTab(obs),
                viewedTabForLastVisit: {"Vasculite": true}
            }
        }
        case "setVisibleSection":

            const rows = buildView(state.observations, state.visits, questionnaire, action.visibleSectionLabel)

            return {
                ...state,
                visibleSectionLabel: action.visibleSectionLabel,
                selectedObservationType: null,
                rows,
                viewedTabForLastVisit: {...state.viewedTabForLastVisit, [action.visibleSectionLabel]: true}
            }
        case "newObservation": {

            const newObsId = state.idGen + 1

            const newState = {
                ...state,
                observations: [...state.observations, {
                    id: newObsId,
                    visitId: action.visit.id,
                    observationTypeId: action.observationTypeId,
                    fieldValues: action.fieldValues
                }]
            }
            return {
                ...newState,
                idGen: newObsId + 1,
                rows: buildView(newState.observations, newState.visits, questionnaire, newState.visibleSectionLabel, action.observationTypeId),
                observationCountPerTab: countObservationsPerTab(newState.observations)
            }
        }
        case "deleteObservation": {
            const newState = {
                ...state,
                observations: state.observations.filter(o =>
                    ! (o.visitId === action.visitId && o.observationTypeId === action.observationTypeId)
                )
            }
            return {
                ...newState,
                rows: buildView(newState.observations, newState.visits, questionnaire, newState.visibleSectionLabel, action.observationTypeId)
            }
        }
        case "updateObservationFields": {
                let observationExists = false
                let newState = {
                    ...state,
                    observations: state.observations.map(o => {
                        if(o.visitId === action.visitId && o.observationTypeId === action.observationTypeId) {
                            observationExists = true
                            return {
                                ...o,
                                fieldValues: action.fieldValues
                            }
                        }
                        return o
                    })
                }

                if(! observationExists) {
                    newState = {
                        ...state,
                        observations: [...state.observations, {
                            visitId: action.visitId,
                            observationTypeId: action.observationTypeId,
                            fieldValues: action.fieldValues
                        }]
                    }
                }

                return {
                    ...newState,
                    rows: buildView(newState.observations, newState.visits, questionnaire, newState.visibleSectionLabel, action.observationTypeId)
                }
            }
        case "selectObservationType": {
            return {
                ...state,
                selectedObservationType: action.observationTypeId,
                rows: buildView(state.observations, state.visits, questionnaire, state.visibleSectionLabel, action.observationTypeId)
            }
        }
        case "newVisit": {

            const today = new Date()
            let defaultVisitDate = today

            if(state.visits.length > 0) {
                const maxDate = max(state.visits.map(v => v.date))

                if(differenceInDays(today, maxDate) <= 0) {
                    defaultVisitDate = addDays(maxDate, 1)
                }
            }

            const newVisitId = state.idGen + 1

            const newState = {
                ...state,
                visits: [
                    ...state.visits, {id: newVisitId, date: defaultVisitDate}
                ]
            }
            return {
                ...newState,
                idGen: newVisitId,
                rows: buildView(newState.observations, newState.visits, questionnaire, newState.visibleSectionLabel, newState.selectedObservationType)
            }
        }
        case "updateVisitTime": {
            return {
                ...state,
                visits: state.visits.map(v => {
                    if (v.id !== action.visitId) {
                        return v
                    } else {
                        return {
                            ...v,
                            date: action.date
                        }
                    }
                })
            }
        }
        default:
            throw Error(`unknown action ${name}`)
    }
}

