import React, {useEffect, useReducer, useState} from "react";
import {healthOverviewReducer} from "./QuestionnaireTableReducer";
import { Button, Tooltip } from "flowbite-react";
import {sort} from "radash";
import {getUnixTime} from "date-fns";
import NavBar from "../widgets/NavBar";
import Card from "../widgets/Card";
import {VasculiteForm} from "./CustomForms";
import {Tab, Tabs} from "../widgets/Tabs";
import {QuestionaireTable} from "./QuestionaireTable";
import questionnaire from "../metaData";
import {DefaultButton, AlternativeButton, SmallButton} from "../widgets/Buttons";
import ModalPopup from "../widgets/ModalPopup";
import {useNavigate} from "react-router-dom";


const sectionsQuestionnaire = [
    questionnaire.children[0],
    questionnaire.children[1],
    questionnaire.children[2],
    questionnaire.children[3],
    questionnaire.children[4],
    questionnaire.children[5],
    questionnaire.children[6]
]


export const Questionnaire = ({}) => {

    const navigate  = useNavigate()

    const [selectedSection, setSelectedSection] = useState(sectionsQuestionnaire[0].label)

    const [isEditVasculiteForm, setIsEditVasculiteForm] = useState(false)


    const [healthOverviewState, healthOverviewDispatch] = useReducer(
        healthOverviewReducer, healthOverviewReducer({}, {name: "init"})
    )

    useEffect(() => {
        healthOverviewDispatch({
            name: "setVisibleSection",
            visibleSectionLabel: selectedSection
        })
    }, [selectedSection])

    const [currentVisit, ...otherVisits] = sort(
        healthOverviewState.visits,
        visit => getUnixTime(visit.date),
        true
    )

    const popEditVasculiteForm = () =>
        <ModalPopup
                marginTop={200}
                title={"Infos sur la Vasculite"}
                onClose={() => setIsEditVasculiteForm(false)}
                footer={<>
                    <DefaultButton
                        caption={"Sauvegarder"}
                        onClick={() => {
                            // onSaveClick(fieldValues)
                            setIsEditVasculiteForm(false)
                        }}
                    />
                    <AlternativeButton
                        caption={"Annuler"}
                        onClick={() => setIsEditVasculiteForm(false)}
                    />
                </>}
            >
                <VasculiteForm isEditMode={true}/>
            </ModalPopup>

    return <>
        <NavBar>
            <div className="flex flex-row">
                <div className={"basis-4/5"}>
                    <Card onClick={() => setIsEditVasculiteForm(true)}>
                        <VasculiteForm/>
                    </Card>
                </div>
                <div className={"basis-1/5"}>
                    <div style={{margin: 48}}>
                        <SmallButton
                            caption={"Quitter le Questionnaire"}
                            onClick={() => navigate("/")}
                        />
                    </div>
                </div>
            </div>
                <Tabs>
                    {
                        sectionsQuestionnaire.map(
                            section => {

                                let tabCounter =
                                    healthOverviewState.observationCountPerTab[section.label]

                                let tabCounterLabel = ""

                                if(tabCounter > 0) {
                                    tabCounterLabel = ` (${tabCounter}) `
                                }
                                else if(healthOverviewState.viewedTabForLastVisit[section.label]) {
                                    tabCounterLabel = " (0)"
                                }

                                const renderTabTool = () => {
                                    if(tabCounterLabel === "") {
                                        return "Vous n'avez pas parcouru cette section du questionnaire"
                                    }
                                    else if (tabCounterLabel === " (0)") {
                                        return "Vous avez parcouru cette section du questionnaire, mais saisi aucune donnée."
                                    }

                                    return `Vous avez saisi ${healthOverviewState.observationCountPerTab[section.label]} éléments de questionnaire dans cette section.`

                                }

                                return <Tooltip content={renderTabTool()} key={section.id}>
                                        <Tab
                                        className="me-2"
                                        key={section.id}
                                        label={section.label + tabCounterLabel}
                                        isSelected={selectedSection === section.label}
                                        onClick={() => setSelectedSection(section.label)}
                                    />
                                </Tooltip>
                            }
                        )
                    }
                </Tabs>
        </NavBar>
        <div style={{marginTop: 290}}></div>
        <div className="flex flex-row">
        <div className={"basis-1/5"}></div>
            <div className={"basis-3/5"}>
                <QuestionaireTable
                    currentVisit={currentVisit}
                    healthOverviewState={healthOverviewState}
                    healthOverviewDispatch={healthOverviewDispatch}
                />
            </div>
            <div className={"basis-1/5"}></div>
        </div>
        {isEditVasculiteForm && popEditVasculiteForm()}
    </>

}