import React from "react";
import {ObservationView} from "./ObservationView";
import {PencilAddNoteIcon} from "../widgets/CustomIcons";


export const QuestionaireTable = ({currentVisit, healthOverviewState, healthOverviewDispatch}) => {


    const renderRow = row => {

        const rowHasObservations = () => {
            for (let vo of row.visitObservations) {
                if(vo.observations) {
                    return true
                }
            }
        }

        if (row.type === 'SelectedObservationTypePanel') {
            return <tr
                key={`tr-${row.label}-${row.o.id}`}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
                <ObservationView
                    row={row}
                    currentVisit={currentVisit}
                    healthOverviewState={healthOverviewState}
                    healthOverviewDispatch={healthOverviewDispatch}
                />
            </tr>
        } else {

            return <tr
                key={`tr-${row.label}-${row.o.id}`}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
                <td
                    key={`c1`}
                    className={"px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"}
                    style={{
                        borderRight: "solid 1px #E5E7EB",
                        minWidth: 1000
                }}
                >
                    <div style={{marginLeft: `${(row.depth -2) * 2}rem`}}>
                        <div
                            onClick={
                                () => {
                                    switch (row.depth) {
                                        case 0:
                                            selectVisibleSection(row.label)
                                            break
                                        case 3:
                                            healthOverviewDispatch({
                                                name: "selectObservationType",
                                                observationTypeId: row.o.id
                                            })
                                            break
                                    }
                                }
                            }
                            style={{cursor: 'pointer'}}
                        >{row.label}
                        </div>
                    </div>
                </td>
                <td>
                    {rowHasObservations() ? <PencilAddNoteIcon/> : null}
                </td>
            </tr>
        }
    }

    return <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead>
            <tr>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {healthOverviewState.rows.map(row => {
                return renderRow(row)
            })}
            </tbody>
        </table>
    </div>
}