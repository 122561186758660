import React, {useState} from "react";
import Field from "./Field";
import ModalPopup from "../widgets/ModalPopup";
import {DefaultButton, AlternativeButton, ExtraSmallButton} from "../widgets/Buttons";
import Note from "../widgets/Note";


export const EditObservationPopup = ({observation, title, onSaveClick, onCancelClick, adatas}) => {

    const [fieldValues, setFieldValues] = useState(observation ? observation.fieldValues: {})

    const renderFieldList = () => adatas.map(adata =>
        <Field
            key={`adata-${adata.fieldName}`}
            fieldValues={fieldValues}
            adata={adata}
            onFieldEdit={(name, value) => setFieldValues({
                ...fieldValues,
                [name]: value
            })}
        />
    )

    return <ModalPopup
        marginTop={200}
        title={title}
        onClose={onCancelClick}
        footer={<>
            <DefaultButton
                caption={"Save"}
                onClick={() => onSaveClick(fieldValues)}
            />
            <AlternativeButton
                caption={"Cancel"}
                onClick={onCancelClick}
            />
        </>}
    >
        {
            renderFieldList()
        }

        <Note text={"voici une note"} date={new Date()}/>

        <Note text={"et une note, avec pas mal plus de texte"} date={new Date()}/>

        <div style={{marginTop: 14}}>
            <ExtraSmallButton caption={"ajouter une note"}/>
        </div>

    </ModalPopup>
}